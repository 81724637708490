import { HeaderSection } from "@brutextiles/web-component-library";
import { Helmet, withAuthentication } from "@containers";
import { PageViews } from "@piwikpro/react-piwik-pro";
import { userContextState } from "@recoil";
import { LayoutServiceData, PageContext, PageData } from "@types";
import { UserContext } from "@types/user";
import { marginMapper } from "@utils";
import { graphql } from "gatsby";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useRecoilValue } from "recoil";

interface Props {
    data: PageData;
    dynamicId?: string;
    pageContext: PageContext;
    uri: string;
}

const PageLayout = ({
    data: { sitecoreLayoutPage: pageData },
    dynamicId,
    uri,
    pageContext,
}: Props): ReactElement => {
    /* variables */
    const data = JSON.parse(pageData.internal.content) as LayoutServiceData;

    // TODO: Temporary solution until changes in WCL HeaderSection is implemented
    const [headerMounted, setHeaderMounted] = useState<boolean>(false);

    // TODO: Temporary fix value for the Sitecore Placeholders. Upcoming
    // cleanup fix will be done in Sitecore.
    const projectName = "brugle";
    const userContext = useRecoilValue(
        userContextState,
    ) as unknown as UserContext;

    const title = useMemo(
        () =>
            `${data.sitecore.route?.displayName} | ${userContext?.currentAccount?.accountName}`,
        [
            data.sitecore.route?.displayName,
            userContext?.currentAccount?.accountName,
        ],
    );

    // Get staticData content from Sitecore common for all pages.
    // This contains the header and footer data.
    const staticDataHeader =
        data.sitecore.staticData?.placeholders[`${projectName}-header`][0];
    const staticDataFooter =
        data.sitecore.staticData?.placeholders[`${projectName}-footer`][0];

    const pageFields = useMemo(
        () => data.sitecore.route.fields,
        [data.sitecore.route.fields],
    );

    // Get the componentNames added in Sitecore LayoutService.
    const componentNames = Object.values(data.sitecore.route.placeholders)
        ?.map(value => value?.map(({ componentName }) => componentName))
        ?.flat();

    // Map the component based on the componentName from Sitcore and containers in the project.
    let components;
    componentNames?.map(componentName => {
        let component;

        try {
            // Try fetching the actual container for the Layout Service data

            /* eslint-disable-next-line */
            component = require(`../containers/${componentName}`).default;
        } catch (error) {
            // Fetch a temporary placeholder container
            // when the actual container does not yet exist.

            /* eslint-disable-next-line */
            component = require(`../containers/NoContainer`).default;
        }

        components = {
            ...components,
            [componentName]: component,
        };
    });

    let CustomHeader;
    try {
        // Try fetching the configured header.
        const componentName = staticDataHeader?.componentName;
        /* eslint-disable-next-line */
        CustomHeader = require(`../containers/${componentName}`).default as any;
    } catch (error) {
        // Fetch a temporary placeholder container
        // when the actual container does not yet exist.

        /* eslint-disable-next-line */
        CustomHeader = require(`../containers/NoContainer`).default as any;
    }

    let CustomFooter;
    try {
        // Try fetching the configured footer.
        const componentName = staticDataFooter?.componentName;
        /* eslint-disable-next-line */
        CustomFooter = require(`../containers/${componentName}`).default as any;
    } catch (error) {
        // Fetch a temporary placeholder container
        // when the actual container does not yet exist.

        /* eslint-disable-next-line */
        CustomFooter = require(`../containers/NoContainer`).default as any;
    }

    useEffect(() => {
        if (title) {
            PageViews.trackPageView(title);
        }
    }, [title]);

    return (
        <div className="layoutContainer">
            <Helmet
                title={title}
                description={pageFields?.metaDescription?.value}
                ogSiteName={projectName}
                ogTitle={title}
                ogDescription={pageFields?.metaOgDescription?.value}
                ogLanguage={process.env.GATSBY_DEFAULT_LANGUAGE}
                ogType="website"
                ogUrl={window.location.href}
                ogImage={pageFields.metaOgImage?.value?.src}
            />

            {/*Sitecore - staticData header */}
            {staticDataHeader && (
                <CustomHeader
                    key={`${projectName}-header`}
                    projectName={projectName}
                    fields={staticDataHeader.fields}
                    pathName={uri}
                    pageSlug={pageContext.slug}
                    mounted={() => setHeaderMounted(true)}
                />
            )}

            {/* Components added here are sticky below the header */}
            {headerMounted && (
                <HeaderSection>
                    {/*Sitecore - projectName-header placeholder */}
                    {data.sitecore.route.placeholders[
                        `${projectName}-header`
                    ]?.map(
                        ({
                            dataSource,
                            componentName,
                            fields,
                            params,
                            uid,
                        }) => {
                            const CustomComponent = components[componentName];

                            return (
                                <div
                                    key={uid}
                                    id={dataSource
                                        ?.replace(/[{}]/g, "")
                                        .toLowerCase()}
                                    style={marginMapper(params)}
                                    className="full-width"
                                >
                                    <ErrorBoundary
                                        fallbackRender={() => null}
                                        onError={(error, info) =>
                                            console.error(
                                                "something went wrong",
                                                error,
                                                info,
                                            )
                                        }
                                    >
                                        <CustomComponent
                                            componentName={componentName}
                                            dataSource={dataSource}
                                            fields={fields}
                                            dynamicId={dynamicId}
                                            pageId={data.sitecore.route.itemId}
                                        />
                                    </ErrorBoundary>
                                </div>
                            );
                        },
                    )}
                </HeaderSection>
            )}

            <div className="navigationStyler">
                {/*Sitecore - projectName-content placeholder */}
                {data.sitecore.route.placeholders[
                    `${projectName}-content`
                ]?.map(({ dataSource, componentName, fields, params, uid }) => {
                    const CustomComponent = components[componentName];
                    const fullWidth = params["Full Width"] === "1";
                    if (fullWidth) delete params["Full Width"];

                    return (
                        <div
                            key={uid}
                            id={dataSource?.replace(/[{}]/g, "").toLowerCase()}
                            style={marginMapper(params)}
                            className={fullWidth ? "full-width" : undefined}
                        >
                            <ErrorBoundary
                                fallbackRender={() => null}
                                onError={(error, info) => {
                                    // TODO ; create MS Teams incoming webhook connected to channel & send notifications to that channel whenever an ErrorBoundary occurred.
                                    // REF ; https://medium.com/@john_93626/automating-posts-to-microsoft-teams-using-javascript-2b5afeab1974
                                    console.error(
                                        "something went wrong",
                                        error,
                                        info,
                                    );
                                }}
                            >
                                <CustomComponent
                                    componentName={componentName}
                                    dataSource={dataSource}
                                    fields={fields}
                                    dynamicId={dynamicId}
                                />
                            </ErrorBoundary>
                        </div>
                    );
                })}

                {staticDataFooter && (
                    <CustomFooter
                        key={`${projectName}-footer`}
                        fields={staticDataFooter.fields}
                    />
                )}
            </div>
        </div>
    );
};

export default withAuthentication(PageLayout);

export const query = graphql`
    query ($id: String!) {
        sitecoreLayoutPage(id: { eq: $id }) {
            internal {
                content
            }
        }
    }
`;
